import React from 'react'
import styled from 'styled-components'

const Wrapper = styled.div`
  width: 100%;
  max-height: 500px;
  margin: 2rem auto;
  overflow-y: scroll;

  p {
    font-size: 0.9em;
    line-height: 1.4em;
  }
`

export const Terms: React.FC = () => (
  <>
    <Wrapper>
      <p>
        To any and all prospective holders, purchasers, assignees, or transferees (“You” or “Your”, as applicable) of
        the PrimeDAO Ethereum tokens (“PRIME” Tokens”), by your participation in the relevant token distribution and
        interaction with the materials herein and with the PRIME Tokens themselves, You acknowledge Your understanding
        and agreement with the disclaimers herein:
      </p>
      <p>
        PRIME Tokens permit use of software created by the PrimeDAO Decentralized Autonomous Organization (“PrimeDAO”),
        and are not to be treated as investments of any nature. The PRIME Token does not impart or guarantee any
        economic benefits or returns of any kind to its holders, and staking or locking the PRIME Token may involve
        different risks of loss of the PRIME Token. The operation, maintenance and overall success of the PrimeDAO and
        protocol depends on the efforts of a disparate group of actors constituting the PrimeDAO and sub-DAOs therein,
        and as such the products and services described herein involve substantial risk. Materials published by the
        PrimeDAO, The Prime Development Foundation (the “Foundation”), or any affiliate or related entity to the
        foregoing (collectively, the Prime “Network”) do not constitute the provision of advisory services regarding
        investment, tax, legal, financial, accounting, consulting or any other related services, nor are they a
        recommendation to purchase any token, product or service. All materials published by the Network reflect the
        information available as of the time of publishing, are subject to change at any time without notice, and shall
        in no circumstance incur any liability for any direct or consequential loss arising out of the use of any
        Network materials or communications.
      </p>
      <p>
        You represent and warrant You are not: (i) in violation of any laws relating to economic sanctions, anti-money
        laundering, or anti-corruption; (ii) restricted or prohibited in dealings by a global sanctions authority
        (including but not limited to the Security Council of the United Nations, the European Union, or the U.S. Office
        of Foreign Assets and Control) (a "Sanctions Authority"), (iii) a citizen of or domiciled in (at any time) a
        country sanctioned by a Sanctions Authority including, but not limited to, Cuba, Iran, Syria, North Korea, and
        the Crimea region of Ukraine; and (iv) a citizen of or domiciled in any of the following jurisdictions for the
        PRIME Token distribution: the United States (including its territories and dependencies, any state of the United
        States and the District of Columbia), Afghanistan, Ethiopia, Guyana, Iraq, Sudan, Venezuela, or Yemen.
      </p>
      <p>
        PrimeDAO has undertaken commercially reasonable screening measures, to the extent possible in a distribution
        performed by a decentralized exchange and accompanying DAO, to prevent any excluded persons or jurisdictions
        from participating in the sale, but as an additional measure relies upon Your representation that You are not
        excluded from participation in accord with the foregoing. Furthermore, PrimeDAO expressly disclaims and disavows
        any secondary transfers, sales, or assignments of the PRIME Tokens by You and shall have no liability whatsoever
        for any such actions, further recipients, or usage of the PRIME Tokens.
      </p>
      <p>
        Purchasing PRIME Tokens involves a high degree of various risks, including a possible loss of the entire
        purchase price. PrimeDAO’s sector and market is highly competitive, new, and subject to innumerable legal and
        regulatory uncertainties, including as to the status or classification of the PRIME Token itself and its
        distribution, the status of the PrimeDAO and its dealings, and the legal and regulatory treatment of the wider
        decentralized technology space generally. ​At any time PrimeDAO and/or the Foundation may be compelled to cease
        operations or take actions that result in its dissolution—while such actions may not affect the continuance of
        the PrimeDAO, they may impact PrimeDAO’s and PRIME’s developments, legal classification, intellectual property
        status, commercial relationships, and other interactions between the various entities that support the
        development of PRIME.
      </p>
      <p>
        A private key, or a combination of private keys, is necessary to send and otherwise interact with PRIME Tokens
        stored in Your Ethereum wallet. Accordingly, loss of sole control of such private key(s) (including by any third
        party’s access to such private keys) associated with Your wallet can result in a loss of such PRIME Tokens.
        Further, Your failure to properly follow the procedures for PRIME Token distribution, for example providing an
        incorrect or non-compatible wallet address, may result in the loss of Your PRIME Tokens.
      </p>
      <p>
        PrimeDAO and its corresponding product suite is still under development and may undergo significant changes over
        time and although PrimeDAO intends for the PRIME Tokens to function as described in the materials herein and
        accompanying documentation, PrimeDAO is free to alter the course of development or services (including the usage
        or purpose of PRIME Tokens) as it chooses/as deemed advisable by the direction of the DAO’s governance process.
        Therefore, PRIME Tokens may not meet any specific expectations You may have concerning the PRIME Token’s value,
        utility, or usage.
      </p>
      <p>
        Malicious actors may attempt to interfere with the token distribution platform or the PRIME Tokens in a variety
        of ways, including, but not limited to, malware attacks, denial of service attacks, consensus-based attacks,
        Sybil attacks, smurfing and spoofing, any of the foregoing in manners out of PrimeDAO’s control. Furthermore,
        because PrimeDAO’s products and DAO are substantially based on open source software, there is a risk that a
        third party or a PrimeDAO member may intentionally or unintentionally introduce weaknesses into the core
        infrastructure of the PrimeDAO or its products, which could negatively affect the PRIME Tokens, including their
        utility.
      </p>
      <p>
        The legal and regulatory status and classification of PRIME Tokens, DAOs and blockchain technology generally is
        unclear or unsettled in many jurisdictions. It is difficult to predict how or whether governments and regulatory
        agencies may apply existing regulations and law, or enact future regulations or legislation, with respect to
        such technology and its future applications, including PrimeDAO and the PRIME Tokens. Enforcement actions could
        negatively impact PrimeDAO and the PRIME Tokens in various ways, including but not limited to (solely for
        purposes of illustration) through a determination that the offering and distribution/purchase of the PRIME
        Tokens constitutes unlawful activity or that the PRIME Tokens represent regulated instruments requiring
        registration or licensing of those instruments or some or all of the parties involved in the offering and
        distribution/purchase thereof. PrimeDAO may cease to offer its services to people in specific jurisdictions in
        the event that regulatory actions, or changes to law or regulation make it illegal to operate in such
        jurisdiction, or commercially undesirable for PRIME to obtain the necessary regulatory approval(s) to operate in
        such jurisdiction.
      </p>
      <p>
        THIS WEBSITE, THE LINKS AND OTHER MATERIALS PROVIDED HEREIN, AND ANY COMMUNICATIONS RELATED TO ANY OF THE
        FOREGOING ARE NOT INTENDED TO CONSTITUTE AN OFFER TO SELL, OR SOLICITATION OF AN OFFER TO BUY, ANY SECURITIES.
        ANY AND ALL ‘SECURITIES’ REFERRED TO HEREIN, SHOULD THEY BE SO DESIGNATED BY A REGULATORY ENTITY WITH
        JURISDICTION, HAVE NOT BEEN REGISTERED UNDER ANY APPLICABLE SECURITIES LAWS. THE SECURITIES AND EXCHANGE
        COMMISSION NOR ANY OTHER SECURITIES COMMISSION OR AGENCY HAS REVIEWED OR CONFIRMED THE ACCURACY OR ADEQUACY OF
        THE CONTENTS HEREOF.
      </p>
      <p>
        NOTHING SET FORTH HEREIN IS INTENDED TO BE LEGAL, TAX OR FINANCIAL ADVICE TO ANY ENTITY OR PERSON. NO
        REPRESENTATION, WARRANTY OR ASSURANCE IS MADE REGARDING THE ACCURACY OF THE INFORMATION CONTAINED HEREIN, AND IT
        IS NOT INTENDED TO BE RELIED UPON BY ANY PERSON. THE INFORMATION CONTAINED HEREIN MAY NOT REFLECT SUBSEQUENT
        DEVELOPMENTS.
      </p>
      <p>
        THE FORWARD-LOOKING STATEMENTS IN THIS WEBSITE OR THE LINKS AND OTHER RELATED MATERIALS ARE SUBJECT TO NUMEROUS
        ASSUMPTIONS, RISKS AND UNCERTAINTIES WHICH ARE SUBJECT TO CHANGE OVER TIME. THERE ARE MANY RISK FACTORS,
        INCLUDING THOSE RELATING TO DISTRIBUTED LEDGER AND BLOCKCHAIN TECHNOLOGY GENERALLY, AS WELL ANY OPERATIONS AND
        RESULTS OF OPERATIONS, THAT COULD CAUSE ACTUAL RESULTS OR DEVELOPMENTS TO NOT BE REALIZED IN WHOLE OR IN PART
        OR, EVEN IF SUBSTANTIALLY REALIZED, TO FAIL TO ACHIEVE ANY OR ALL OF THE BENEFITS THAT COULD BE EXPECTED
        THEREFROM. PRIMEDAO AND ALL RELATED AND/OR INTERESTED ENTITIES RESERVE THE RIGHT TO CHANGE THE PLANS,
        EXPECTATIONS AND INTENTIONS STATED HEREIN AT ANY TIME AND FOR ANY REASON, IN THEIR RESPECTIVE SOLE AND ABSOLUTE
        DISCRETION, AND, EXCEPT AS MAY BE OTHERWISE REQUIRED BY APPLICABLE LAW. PRIMEDAO UNDERTAKES NO OBLIGATION TO
        UPDATE PUBLICLY OR REVISE ANY FORWARD-LOOKING STATEMENT, WHETHER AS A RESULT OF NEW INFORMATION, FUTURE
        DEVELOPMENTS OR OTHERWISE. YOU DO NOT RELY ON, AND DO NOT MAKE ANY FINANCIAL DECISION OR INVESTMENT BASED ON,
        THE STATEMENTS CONTAINED IN THIS WEBSITE, THE LINKS AND OTHER MATERIALS PROVIDED HEREIN, AND ANY RELATED
        COMMUNICATIONS -- INCLUDING BUT NOT LIMITED TO ANY SELLING OR TRADING OF ANY CRYPTOGRAPHIC OR BLOCKCHAIN TOKEN
        FOR ANY PURPOSE, OR THE SECURITIES OF ANY COMPANY OR OTHER ORGANIZATION OR GROUP.
      </p>
    </Wrapper>
  </>
)

export default Terms
